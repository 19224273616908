export const config = {
    COIN_DECIMALS: 6,
    PREFIX: 'osmo',
    // CONTRACT_ADDRESS: 'osmo17kqws7fy2qnejdw68cqjlw456gdxcdukk0wq6026u826zlda3y7qdhzqlv',
    CONTRACT_ADDRESS: 'osmo1994s0ea4z2lqrh5gl8l5s0cw6hwz92s3pn2yhkamfh57j9yh7lxssnr80s',
    DENOM: 'OSMO',
    DENOM_IBC: 'ibc/498A0751C798A0D9A389AA3691123DADA57DAA4FE165D5C75894505B876BA6E4',
};

export const DEV_URL = 'https://data-api.streamswap.io';
export const STREAMS = [2];
export const STREAM_8 = 'LAB';
export const STREAM_9 = 'TNKR';
export const STREAM_10 = 'HAVA';
export const STREAM_2 = 'SHERPA';
export const DEFAULT_SKIP = 0;
export const DEFAULT_LIMIT = 10;
export const DEFAULT_SORT_BY = 'total';
export const DEFAULT_ORDER = 'desc';
